






























import type { Picture } from '@/inc/types'

import gsap from 'gsap'
import ScrollTrigger from 'gsap/dist/ScrollTrigger.js'
import {
  defineComponent,
  ref,
  PropType,
  onMounted,
  onBeforeUnmount,
} from '@vue/composition-api'

interface StackedPictures {
  items: {
    headline: string
    title: string
    htmltext: string
    picture: Picture
  }[]
}

import UiPicture from '@/components/ui/Picture.vue'

export default defineComponent({
  name: 'FlexibleStackedPictures',
  components: { UiPicture },
  props: {
    content: {
      type: Object as PropType<StackedPictures>,
      required: true,
    },
  },

  setup() {
    const rootEl = ref<HTMLElement | null>(null)
    const id = 'stacked-pictures'
    const mq = '(min-width: 960px)'
    let tl: gsap.core.Timeline | undefined = undefined
    let picturesEl: HTMLElement[] = []

    // Create desktop animation
    const initTl = () => {
      if (!rootEl.value) {
        return
      }

      // Select all the targets
      picturesEl = Array.from(
        rootEl.value.querySelectorAll(
          // 'li:not(:last-child) .stacked-pictures__item__picture'
          '.stacked-pictures__item__picture'
        )
      )
      tl = gsap.timeline({
        scrollTrigger: {
          trigger: rootEl.value,
          scrub: 0.5,
          id,
          start: '30% 50%',
          end: 'bottom 50%',
        },
      })

      const leave = {
        yPercent: -10,
        opacity: 0,
        ease: 'power4.in',
        duration: 0.2,
      }
      const enter = {
        /* eslint-disable quote-props */
        width: '100%',
        '--item-offset': '0rem',
        ease: 'power4.out',
        /* eslint-enable quote-props */
      }

      picturesEl.forEach((el, index) => {
        if (index === 0) {
          return
        }

        tl?.add(`${index}`, index === 1 ? 0 : '+=1')
          .to(picturesEl[index - 1], { ...leave }, `${index}`)
          .to(el, { ...enter })
      })
    }

    // Delete/init timeline based on media query
    const onResize = () => {
      if (window.matchMedia(mq).matches) {
        if (!ScrollTrigger.getById(id)) {
          // Switch to desktop: init tl
          initTl()
        }
      } else if (ScrollTrigger.getById(id)) {
        // Switch to mobile: delete tl and tweens
        tl?.kill()
        ScrollTrigger.getById(id)?.kill()
        gsap.set(picturesEl, { clearProps: true })
      }

      ScrollTrigger.refresh()
    }

    onMounted(() => {
      // Init desktop animation
      if (window.matchMedia(mq).matches) {
        initTl()
      }
    })

    onBeforeUnmount(() => {
      // Destroy ScrollTrigger instance
      ScrollTrigger.getById(id)?.kill()
    })

    return {
      rootEl,
      onResize,
    }
  },
})
